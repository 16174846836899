<template>
  <div class="input-group" :class="{ 'input--error': v && v.$error }">
    <label
      :for="`textarea-${slugify(name || '')}`"
      :class="['label', { 'label--bold': labelBold }]"
    >
      {{ label }}
      <span v-if="required" class="required"> *</span>
    </label>
    <textarea
      v-bind="$attrs"
      :id="`textarea-${slugify(name || '')}`"
      v-model="handleInput"
      :class="['input', errorClass]"
      :data-testid="`${formatNameForTestid(name)}_textarea`"
      :name="name"
      autocomplete="off"
      @blur="v && v.$touch()"
    />
    <span v-if="showErrorMessage" class="error">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { Validation } from '@vuelidate/core'
import { formatNameForTestid } from '@/utils/forms'
import { slugify } from '@/utils/helpers'

defineOptions({
  inheritAttrs: false,
})

interface Props {
  modelValue: string
  label: string
  required?: boolean
  name?: string
  labelBold?: boolean
  v?: Validation['ExtractRulesResults']
}
const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string): void
}>()

const { handleInput, errorMessage, errorClass, showErrorMessage } = useInput(
  props,
  emit,
)
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
}

.label {
  &--bold {
    font-weight: 600;
  }
}
</style>
